.policy-page__description{
    font-size: calc(1vw + 5px);
    font-weight: 400;
    color: #111111;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    font-family: 'Inter', sans-serif;
}
.policy-page__title{
    font-size: calc(2vw + 15px);
    font-weight: 700;
    font-family: 'Sofia Sans', sans-serif;
    text-align: center;
}
.policy-page__heading{
    font-size: calc(1vw + 10px);
    font-weight: 700;
    font-family: 'Sofia Sans', sans-serif;
  
}
.policy-page{
   height: auto;
    padding: 60px 8vw;
}

@media screen and (max-width:770px){
    .policy-page__description{
        font-size: calc(1vw + 10px);
      
    }
    .policy-page__title{
        font-size: calc(2vw + 20px);
       
    }
    .policy-page__heading{
        font-size: calc(1vw + 15px);
      
      
    }
}